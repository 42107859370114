/* @font-face {
  font-family: 'Calibri';
  src: local('Calibri'),
    url(./assets/fonts/Calibri/Calibri.ttf) format('opentype');
} */

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.App {
  text-align: center;
  /* font-family: Calibri; */
  font-family: 'Inter', sans-serif;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.rb-logo {
  font-family: 'Sora', sans-serif;
  font-weight: bolder;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cursor-pointer {
  cursor: pointer;
}

.main-header {
  background-color: 'white';
  z-index: 10;
  box-shadow: '5px 5px 5px grey';
  /* height: 60px;
  line-height: 20px;
  font-size: 40px;
  color: #ffd36b;
  font-family: "Patrick Hand", cursive; */
}
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
}

body {
  background-color: #ffffff;
}

canvas {
  background-color: #ffffff;
}

.profileImage {
  font-family: Arial, Helvetica, sans-serif;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: #61dafb;
  font-size: 3.5rem;
  color: #ffffff;
  text-align: center;
  line-height: 10rem;
  margin: 2rem 0;
}

/* sidebar-bg-color: '#516B8F'  */

/*scrollbar styles*/

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #888;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
